import {CSSProperties, RefObject, useMemo} from 'react'

import {styled, SxProps, Theme, useMediaQuery} from '@waybridge/wui'
import WUITableHead, {TableHeadProps} from '@waybridge/wui/TableHead'
import HeaderShadow from '@waybridge/wui/WTable/HeaderShadow'
import TableHeader from '@waybridge/wui/WTable/TableHeader'
import TableRow from '@waybridge/wui/WTable/TableRow'

import {REZISE_LINE_WIDTH} from '@/hooks/useTableSettings'

import {TableStateProvider} from './Table'
import {TableStateColumn} from './useTableState'
import {useTableContext} from './utils'

export type {TableHeadProps}

const ResizeLine = styled('div')(({theme}) => ({
    zIndex: 1,
    top: '0px',
    height: '100%',
    position: 'absolute',
    cursor: 'col-resize',
    width: `${REZISE_LINE_WIDTH * 6}px`,
    left: `calc(100% - ${REZISE_LINE_WIDTH * 3}px)`,
    boxSizing: 'border-box',

    '&:after': {
        top: 0,
        content: "''",
        display: 'block',
        position: 'absolute',
        left: '50%',
        height: '100%',
        transform: 'translateX(-50%)',
        width: `${REZISE_LINE_WIDTH}px`,
    },

    '&:hover, &:active': {
        '&:after': {
            backgroundColor: theme.palette.grey50,
        },
    },
}))

const TableHead = ({
    columns,
    columnRefs,
    sx = {},
    ...props
}: TableHeadProps & {
    columns: TableStateColumn[]
    columnRefs?: Record<string, RefObject<HTMLDivElement>>
    sx?: SxProps<Theme>
}) => {
    const ctx = useTableContext()

    const tableCtx = useMemo(() => ({...ctx, isHeader: true, isSelectable: ctx.isSelectable ?? false}), [ctx])
    const totalSize = useMemo(
        () => columns.reduce((prev, column) => prev + (column.size ?? column.minSize ?? 0), 0),
        [columns],
    )

    const pagePadding = useMemo(() => {
        const padding = document
            .getElementById('TablePageRoot')
            ?.computedStyleMap()
            .get('padding-left')
            ?.toString()
            .replace('px', '')
        if (!padding) {
            return 0
        }

        return Number(padding)
    }, [])

    const isAboveTotal = useMediaQuery(`(min-width:${totalSize + pagePadding}px)`)

    return (
        <TableStateProvider {...tableCtx}>
            <WUITableHead {...props} sx={{position: 'sticky', top: '0px', zIndex: '3', ...sx}}>
                <TableRow>
                    {(columns || []).map(
                        ({field, label, Header, isLive, size, minSize, isResizable, ...props}, index) =>
                            Header ? (
                                <Header key={field} />
                            ) : (
                                <TableHeader
                                    key={field}
                                    size="small"
                                    {...props}
                                    {...(columnRefs?.[field] && isResizable
                                        ? {
                                              style: {
                                                  ...((props?.sx as CSSProperties)?.position !== 'sticky' && {
                                                      position: 'relative',
                                                  }),
                                                  ...(size
                                                      ? {
                                                            width: `${size}px`,
                                                            minWidth: `${size}px`,
                                                            maxWidth: `${size}px`,
                                                        }
                                                      : {
                                                            minWidth: `${minSize}px`,
                                                            width: `${minSize}px`,
                                                        }),
                                              },
                                          }
                                        : {})}
                                    sx={{
                                        width:
                                            index === columns.length - 1 && isAboveTotal ? '100%!important' : undefined,
                                    }}>
                                    {label}
                                    {columnRefs?.[field] && isResizable ? <ResizeLine ref={columnRefs[field]} /> : null}
                                </TableHeader>
                            ),
                    )}
                </TableRow>
                <HeaderShadow zIndex="3" />
            </WUITableHead>
        </TableStateProvider>
    )
}

export default TableHead
