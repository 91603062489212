import {useCallback, useEffect, useMemo} from 'react'
import {range} from 'lodash-es'

import Skeleton from '@waybridge/wui/Skeleton'
import Stack from '@waybridge/wui/Stack'
import TableBody from '@waybridge/wui/WTable/TableBody'
import TableCell from '@waybridge/wui/WTable/TableCell'
import TableRow from '@waybridge/wui/WTable/TableRow'

import {useCurrentElementIntersects} from '@/hooks/useCurrentElementIntersects'

type Column = {
    field: string
    colSpan?: number
}

type TableFallbackProps = {
    columns?: Column[]
    withoutTableBody?: boolean
    columnNumber?: number
    isOnScreen?: () => void
    intersectionRoot?: React.RefObject<HTMLElement | undefined | null> | null
}

export const TableFallback = ({
    columns,
    intersectionRoot,
    isOnScreen,
    columnNumber,
    withoutTableBody,
}: TableFallbackProps) => {
    const getOptions = useCallback(
        () => ({
            threshold: 0.2,
            root: intersectionRoot?.current,
        }),
        [intersectionRoot],
    )
    const [ref, isIntersecting] = useCurrentElementIntersects<HTMLTableRowElement>(getOptions, Boolean(isOnScreen))
    useEffect(() => {
        if (isIntersecting && isOnScreen) {
            isOnScreen()
        }
    }, [isIntersecting, isOnScreen])

    const processedColumns = useMemo(
        () =>
            !columnNumber
                ? columns?.reduce?.<Column[]>((acc, column, index) => {
                      if ('enabled' in column && !column.enabled) {
                          return acc
                      }
                      if (column.field === 'settings') {
                          if (index + 1 < columns.length) {
                              acc.push({...columns[index + 1], colSpan: 2})
                          }
                          return acc
                      }
                      if (index > 0 && columns[index - 1].field === 'settings') {
                          return acc
                      }
                      acc.push(column)
                      return acc
                  }, [])
                : [],
        [columns, columnNumber],
    )

    const renderCell = (column: Column, index: number) => {
        const props = {
            key: column.field,
            colSpan: column.colSpan || 1,
            style: index === 0 ? {paddingLeft: '7px'} : {},
        }

        switch (column.field) {
            case 'counterparty':
            case 'accountOwner':
                return (
                    <TableCell {...props}>
                        <Stack direction="row" gap={1}>
                            <Skeleton height={24} variant="circular" width={24} />
                            <Skeleton style={{flex: '1'}} />
                        </Stack>
                    </TableCell>
                )
            default:
                return (
                    <TableCell {...props}>
                        <Skeleton />
                    </TableCell>
                )
        }
    }

    const columnComponent = useMemo(() => {
        if (columnNumber) {
            return range(columnNumber).map((number, index) => renderCell({field: `${number}`}, index))
        }

        return processedColumns?.map((column, index) => renderCell(column, index))
    }, [columnNumber, processedColumns])

    const placeholder = range(5).map((number) => (
        <TableRow isZebra key={number} ref={number === 0 ? ref : undefined}>
            {columnComponent}
        </TableRow>
    ))

    return withoutTableBody ? placeholder : <TableBody>{placeholder}</TableBody>
}

export default TableFallback
