import {FormattedMessage} from 'react-intl'

import Button from '@waybridge/wui/Button'
import FullPage from '@waybridge/wui/FullPage'
import {LockIcon} from '@waybridge/wui/Icons'
import Stack from '@waybridge/wui/Stack'
import Typography from '@waybridge/wui/Typography'

import {useAuth} from '@/providers/AuthProvider'

export const PermissionDenied = () => {
    const {clearEffectiveOrganization, effectiveOrganization} = useAuth()
    const handleOrgSwitchClear = () => {
        clearEffectiveOrganization()
        window.location.reload()
    }
    return (
        <Stack direction="row" gap={3}>
            <div>
                <LockIcon fontSize="extra-large" sx={{color: 'red50'}} />
            </div>
            <div style={{maxWidth: '450px'}}>
                <Stack direction="column" gap={3}>
                    <Typography display="block" fontWeight="medium" variant="h4">
                        <FormattedMessage defaultMessage="You don&rsquo;t have permission to view this page." />
                    </Typography>
                    <Typography display="block" variant="body1">
                        <FormattedMessage defaultMessage="Reach out to support if you believe you have encountered this page by mistake." />
                    </Typography>
                    {/* eslint-disable-next-line react/jsx-no-leaked-render, no-implicit-coercion -- FIXME */}
                    {!!effectiveOrganization && (
                        <Typography display="block" variant="body1">
                            <FormattedMessage defaultMessage="Clear your org switch if you believe you are switched into an invalid org." />
                        </Typography>
                    )}
                    <Stack direction="row" gap={1}>
                        <Button href="mailto:support@minehub.com" variant="outlined">
                            <FormattedMessage defaultMessage="Contact support" />
                        </Button>
                        {/* eslint-disable-next-line react/jsx-no-leaked-render, no-implicit-coercion -- FIXME */}
                        {!!effectiveOrganization && (
                            <Button onClick={() => handleOrgSwitchClear()} variant="outlined">
                                <FormattedMessage defaultMessage="Clear org switch" />
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </div>
        </Stack>
    )
}

const PermissionDeniedPage = () => (
    <div style={{display: 'flex', flex: '1', background: 'white'}}>
        <div
            style={{
                flex: 1,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <FullPage>
                <PermissionDenied />
            </FullPage>
        </div>
    </div>
)

export default PermissionDeniedPage
