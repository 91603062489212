/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {ChangeEvent, forwardRef, useCallback} from 'react'
import {isEmpty} from 'lodash-es'

import {styled} from '@waybridge/wui'
import WUITableRow, {TableRowProps as WUITableRowProps} from '@waybridge/wui/TableRow'

import {SelectableCell, TableCell} from './TableCell'
import {useTableContext} from './utils'

export const HoverRow = styled(WUITableRow)(({theme}) => ({
    '&:hover': {
        background: theme.palette.grey10,
    },
}))

export const ZebraRow = styled(WUITableRow)(({theme}) => ({
    background: theme.palette.white,
    '&:nth-of-type(odd)': {
        background: theme.palette.grey10,
    },
}))

export const ZebraHoverRow = styled(ZebraRow)(({theme}) => ({
    '&:hover': {
        background: theme.palette.grey30,
    },
}))

export type TableRowProps = WUITableRowProps & {
    hasHover?: boolean
    isZebra?: boolean
    data?: {id: any}
    isSelectable?: boolean
}

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(function TableRow(
    {
        hasHover = false,
        isZebra = false,
        children,
        data = {id: null},
        isSelectable: isSelectableOverride = true,
        ...props
    },
    ref,
) {
    const {isSelectable, rowSelection, isHeader, allSelected, selectRow, deselectRow, selectAll, deselectAll} =
        useTableContext()
    const isSelected = isSelectableOverride
        ? isHeader
            ? allSelected && isEmpty(rowSelection)
            : rowSelection[data.id] ?? allSelected
        : false
    const isIndeterminate = isHeader && allSelected && !isEmpty(rowSelection)

    const onChange = useCallback(
        (evt: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            evt.stopPropagation()

            // If this is a header row, the select should select all rows
            if (isHeader) {
                if (!checked) {
                    deselectAll()
                } else {
                    selectAll()
                }
            } else {
                if (!checked) {
                    deselectRow(data)
                } else {
                    selectRow(data)
                }
            }
        },
        [isHeader, deselectAll, selectAll, deselectRow, data, selectRow],
    )

    // check if we should show a table cell at the beginning of the row
    const hasStartCell = isSelectable && isSelectableOverride
    // If the row is a body row and does not have an id, we don't want to show a selectable cell
    const hasNoData = !isHeader && data.id === null

    const startCells = hasStartCell ? (
        hasNoData ? (
            <TableCell />
        ) : (
            <SelectableCell
                indeterminate={isIndeterminate}
                isSelected={isSelected}
                onChange={onChange}
                sx={isHeader ? {background: (theme) => theme.palette.white} : undefined}
            />
        )
    ) : null
    if (hasHover && isZebra) {
        return (
            <ZebraHoverRow ref={ref} {...props}>
                {startCells}
                {children}
            </ZebraHoverRow>
        )
    } else if (hasHover) {
        return (
            <HoverRow ref={ref} {...props}>
                {startCells}
                {children}
            </HoverRow>
        )
    } else if (isZebra) {
        return (
            <ZebraRow ref={ref} {...props}>
                {startCells}
                {children}
            </ZebraRow>
        )
    }

    return (
        // eslint-disable-next-line react/jsx-no-leaked-render -- FIXME
        <WUITableRow ref={ref} selected={!isHeader && isSelected} {...props}>
            {startCells}
            {children}
        </WUITableRow>
    )
})

export default TableRow
