import {ElementType, ForwardedRef, forwardRef} from 'react'
import MUIMenuItem, {MenuItemTypeMap} from '@mui/material/MenuItem'
import {OverrideProps} from '@mui/material/OverridableComponent'
import cn from 'classnames'

export type MenuItemProps<
    D extends ElementType = MenuItemTypeMap['defaultComponent'],
    P = Record<string, any>,
> = OverrideProps<MenuItemTypeMap<P, D>, D>

const MenuItem = forwardRef(({className, ...props}: MenuItemProps, ref: ForwardedRef<HTMLLIElement>) => (
    <MUIMenuItem className={cn('MenuItem', className)} ref={ref} {...props} />
))
MenuItem.displayName = 'MenuItem'
export {MenuItem}

export default MenuItem
