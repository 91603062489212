//-------------------------------------
// THIS FILE IS AUTOMATICALLY GENERATED
// Please, do not add types here manually
// Instead: update the scripts/colors.sh file
// RUN `npm run colors`
//-------------------------------------

export const primaryColor = '#008C94'
export const teal = '#005763'
export const darkBlue = '#121226'

export const blue10 = '#F3F5FB'
export const blue20 = '#E3E8F6'
export const blue30 = '#CDD7F0'
export const blue40 = '#ABBCE5'
export const blue50 = '#839BD7'
export const blue60 = '#5262BE'
export const blue70 = '#4751AE'
export const blue80 = '#3F448E'
export const blue90 = '#373C71'
export const green10 = '#EAFFFD'
export const green20 = '#DDF9FA'
export const green30 = '#CCE8EA'
export const green40 = '#8FCBD1'
export const green50 = '#5BACB5'
export const green60 = '#377783'
export const green70 = '#32616C'
export const green80 = '#30525A'
export const green90 = '#2B454E'

export const red10 = '#FDF3F3'
export const red20 = '#FBE5E5'
export const red30 = '#F8D0D0'
export const red40 = '#F1B0B0'
export const red50 = '#EF8585'
export const red60 = '#C63C3C'
export const red70 = '#A62F2F'
export const red80 = '#8A2A2A'
export const red90 = '#732929'

export const yellow10 = '#FEFAEC'
export const yellow20 = '#FCEFC9'
export const yellow30 = '#F9DD8E'
export const yellow40 = '#F7D070'
export const yellow50 = '#F3B02C'
export const yellow60 = '#D16B0E'
export const yellow70 = '#AE4B0F'
export const yellow80 = '#8D3A13'
export const yellow90 = '#743113'

export const grey10 = '#F7F7F7'
export const grey20 = '#EEEEEE'
export const grey30 = '#D9D9D9'
export const grey40 = '#C1C1C1'
export const grey50 = '#B0B0B0'
export const grey60 = '#808080'
export const grey70 = '#5B5959'
export const grey80 = '#3A3939'
export const grey90 = '#121212'
export const black = '#000000'
export const white = '#ffffff'

export interface DesignColors {
    primaryColor: string
    teal: string
    darkBlue: string
    blue10: string
    blue20: string
    blue30: string
    blue40: string
    blue50: string
    blue60: string
    blue70: string
    blue80: string
    blue90: string
    green10: string
    green20: string
    green30: string
    green40: string
    green50: string
    green60: string
    green70: string
    green80: string
    green90: string
    red10: string
    red20: string
    red30: string
    red40: string
    red50: string
    red60: string
    red70: string
    red80: string
    red90: string
    yellow10: string
    yellow20: string
    yellow30: string
    yellow40: string
    yellow50: string
    yellow60: string
    yellow70: string
    yellow80: string
    yellow90: string
    grey10: string
    grey20: string
    grey30: string
    grey40: string
    grey50: string
    grey60: string
    grey70: string
    grey80: string
    grey90: string
    black: string
    white: string
}

export type Color = keyof DesignColors

//-------------------------------------
// Are you adding a new type manually?
// Please, modify scripts/colors.sh instead
//-------------------------------------
