import {ComponentsOverrides, Theme} from '@mui/material/styles'

export const MuiDialogTitle = {
    styleOverrides: {
        root: ({theme}) => ({
            color: theme.palette.black,
            backgroundColor: theme.palette.white,
            borderRadius: 0,
            padding: theme.spacing(3),
            fontSize: theme.typography.h6.fontSize,
            lineHeight: theme.typography.h6.lineHeight,
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2),
            },
            svg: {
                color: theme.palette.secondary[800],
            },
        }),
    } as ComponentsOverrides<Theme>['MuiDialogTitle'],
}

export default MuiDialogTitle
