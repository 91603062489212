import {styled} from '@mui/material'
import MUITooltip, {tooltipClasses, TooltipProps as MUITooltipProps} from '@mui/material/Tooltip'
import cn from 'classnames'
import posthog from 'posthog-js'

export {tooltipClasses} from '@mui/material/Tooltip'

export type TooltipProps = MUITooltipProps & {
    variant?: 'normal' | 'error' | 'warning' | 'white'
    maxWidth?: boolean
    /** a unique ID to track when the tooltip has been opened */
    trackingId?: string
}

const BaseTooltip = styled(({className, ...props}: MUITooltipProps) => (
    <MUITooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        ...theme.typography['tooltip-body'],

        boxShadow: theme.shadows[1],
    },
    [`& .${tooltipClasses.tooltip} a`]: {
        color: theme.palette.primary[200],
        fontWeight: 600,
        textDecoration: 'none',
    },

    [`&.Tooltip-maxWidth .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
}))

const ErrorTooltip = styled(BaseTooltip)(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.red10,
        color: theme.palette.red80,
        boxShadow: theme.shadows[1],
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.red10,
    },
}))

const WarningTooltip = styled(BaseTooltip)(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.yellow10,
        color: theme.palette.yellow80,
        boxShadow: theme.shadows[1],
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.yellow10,
    },
}))

const WhiteTooltip = styled(BaseTooltip)(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.white,
        color: theme.palette.black,
        boxShadow: theme.shadows[1],
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.white,
    },
}))

/**
 * Tooltips display informative text when users hover over, focus on, or tap an element.
 */
export const Tooltip = ({className, variant, maxWidth = false, onOpen, trackingId, ...props}: TooltipProps) => {
    const handleOnOpen = (event: React.SyntheticEvent) => {
        onOpen && onOpen(event)
        posthog.capture('TooltipOpened', {
            trackingId: trackingId ?? 'unknown-tooltip',
            target: (event.target as HTMLElement)?.outerHTML,
            title: typeof props.title === 'string' ? props.title : '',
        })
    }

    if (variant === 'error') {
        return (
            <ErrorTooltip
                className={cn('Tooltip', {'Tooltip-maxWidth': maxWidth}, className)}
                onOpen={handleOnOpen}
                {...props}
            />
        )
    }

    if (variant === 'warning') {
        return (
            <WarningTooltip
                className={cn('Tooltip', {'Tooltip-maxWidth': maxWidth}, className)}
                onOpen={handleOnOpen}
                {...props}
            />
        )
    }

    if (variant === 'white') {
        return (
            <WhiteTooltip
                className={cn('Tooltip', {'Tooltip-maxWidth': maxWidth}, className)}
                onOpen={handleOnOpen}
                {...props}
            />
        )
    }

    return (
        <BaseTooltip
            className={cn('Tooltip', {'Tooltip-maxWidth': maxWidth}, className)}
            onOpen={handleOnOpen}
            {...props}
        />
    )
}

export default Tooltip
