import {forwardRef} from 'react'
import {Stack, styled} from '@mui/material'

import {CircleIcon} from '@waybridge/wui/Icons'
import {StatusColorLight, StatusColorLightest} from '@waybridge/wui/ThemeProvider/minehub-theme/theme'
import {Typography} from '@waybridge/wui/Typography'

type DotItemStatusProps = {
    text: string
    colors: {
        background: StatusColorLightest | StatusColorLight
    }
}
const SmallerCircleIcon = styled(CircleIcon)({
    fontSize: '13px',
})

export const DotItemStatus = forwardRef<HTMLDivElement, DotItemStatusProps>(({colors, text}, ref) => (
    <Stack alignItems="center" component="div" direction="row" gap={1} ref={ref}>
        <SmallerCircleIcon sx={{color: colors.background}} />
        <Typography color="grey80" fontWeight="medium" variant="body1">
            {text}
        </Typography>
    </Stack>
))

DotItemStatus.displayName = 'DotItemStatus'
