import {Components, ComponentsOverrides, Theme} from '@mui/material'

export const MuiToggleButtonGroup: Components<Theme>['MuiToggleButtonGroup'] = {
    styleOverrides: {
        root: ({theme}) => ({
            borderRadius: theme.border.toggleGroup.radius,
            border: `1px solid ${theme.palette.grey40}`,
            backgroundColor: theme.palette.grey20,
            display: 'inline-flex',

            '&.ToggleButtonGroup-equalWidth': {
                width: '100%',
                '& .MuiToggleButtonGroup-grouped': {
                    flex: '1 1 0',
                },
            },

            '&.ToggleButtonGroup-secondary': {
                // dark
                backgroundColor: theme.palette.primary[800],
            },
        }),
        grouped: {
            border: 'none',
        },
        groupedHorizontal: ({theme}) => ({
            '&:first-of-type': {
                borderRadius: theme.border.toggleGroup.radius,
            },
            '&:not(:first-of-type)': {
                borderRadius: theme.border.toggleGroup.radius,
                borderTopLeftRadius: theme.border.toggleGroup.radius,
                borderBottomLeftRadius: theme.border.toggleGroup.radius,
            },
            '&:not(:last-of-type)': {
                borderTopRightRadius: theme.border.toggleGroup.radius,
                borderBottomRightRadius: theme.border.toggleGroup.radius,
            },
        }),
        vertical: {
            borderRadius: 5,
        },
    } as ComponentsOverrides<Theme>['MuiToggleButtonGroup'],
}

export default MuiToggleButtonGroup
