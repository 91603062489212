import {StatusColorLight, StatusColorLightest, StatusColorText} from '@waybridge/wui/ThemeProvider/minehub-theme/theme'

import {AccountStatusType} from '@/pages/InventoryManager/types'

import {
    BalanceInventoryStatus,
    ReceivingStatus,
    TradeInvoiceType,
    TradeStatusNew,
    TransferStatus,
    TransferStatus as TransferStatusType,
} from '@/__generated__/types'

export enum ReceivingStatusExtension {
    Allocated = 'ALLOCATED',
    Remaining = 'REMAINING',
}

export type ReceivingStatusExtended =
    | BalanceInventoryStatus
    | ReceivingStatus
    | ReceivingStatusExtension
    | TradeStatusNew
    | TradeInvoiceType
    | TransferStatusType
    | AccountStatusType

export function getBackgroundColorGeneric<EnumType extends string | number | symbol>(
    colorMap: Record<EnumType, StatusColorLightest | StatusColorLight>,
    instance: EnumType | null,
): StatusColorLightest | StatusColorLight {
    if (instance && instance in colorMap) {
        return colorMap[instance]
    }
    return 'grey40'
}

export function getTextColorGeneric<EnumType extends string | number | symbol>(
    colorMap: Record<EnumType, StatusColorText>,
    instance: EnumType | null,
): StatusColorText {
    if (instance && instance in colorMap) {
        return colorMap[instance]
    }
    return 'grey90'
}

/**
 * Note: The API doesn't return shipments with `PendingIdentifier`
 */
export const receivingStatusBackgroundColorMap: Record<ReceivingStatusExtended, StatusColorLightest> = {
    [ReceivingStatus.Received]: 'primaryColor',
    [ReceivingStatus.Rejected]: 'red60',
    [ReceivingStatusExtension.Allocated]: 'blue90',
    [ReceivingStatusExtension.Remaining]: 'grey40',
    [ReceivingStatus.Arrived]: 'blue90',
    [ReceivingStatus.InTransit]: 'blue60',
    [ReceivingStatus.Booked]: 'blue40',
    [ReceivingStatus.Incomplete]: 'yellow60',
    [ReceivingStatus.InProcess]: 'green40',
    [ReceivingStatus.NotCalculated]: 'grey40',
    [ReceivingStatus.PendingIdentifier]: 'yellow40',
    [TradeStatusNew.Closed]: 'red60',
    [TradeStatusNew.Confirmed]: 'green50',
    [TradeStatusNew.Draft]: 'grey40',
    [TradeStatusNew.Live]: 'blue40',
    [TradeInvoiceType.Invoice]: 'red40',
    [TradeInvoiceType.Debit]: 'red40',
    [TradeInvoiceType.Credit]: 'green40',
    [TradeInvoiceType.Payment]: 'green40',
    [TransferStatusType.Open]: 'yellow40',
    [TransferStatusType.InProgress]: 'green40',
    [TransferStatusType.Late]: 'red60',
    [TransferStatusType.Completed]: 'primaryColor',
    [AccountStatusType.Open]: 'blue40',
    [BalanceInventoryStatus.Released]: 'green40',
    [BalanceInventoryStatus.ReadyForRelease]: 'grey70',
}

export const receivingStatusProgressBackgroundColorMap: Record<ReceivingStatusExtended, StatusColorLightest> = {
    [ReceivingStatus.Received]: 'teal',
    [ReceivingStatus.Rejected]: 'red60',
    [ReceivingStatusExtension.Allocated]: 'blue90',
    [ReceivingStatusExtension.Remaining]: 'grey50',
    [ReceivingStatus.Arrived]: 'green50',
    [ReceivingStatus.InTransit]: 'blue60',
    [ReceivingStatus.Booked]: 'blue40',
    [ReceivingStatus.Incomplete]: 'yellow60',
    [ReceivingStatus.InProcess]: 'green40',
    [ReceivingStatus.NotCalculated]: 'grey40',
    [ReceivingStatus.PendingIdentifier]: 'yellow40',
    [TradeStatusNew.Closed]: 'red60',
    [TradeStatusNew.Confirmed]: 'green50',
    [TradeStatusNew.Draft]: 'grey40',
    [TradeStatusNew.Live]: 'blue40',
    [TradeInvoiceType.Invoice]: 'red40',
    [TradeInvoiceType.Debit]: 'red40',
    [TradeInvoiceType.Credit]: 'green40',
    [TradeInvoiceType.Payment]: 'green40',
    [TransferStatusType.Open]: 'blue40',
    [TransferStatusType.InProgress]: 'yellow40',
    [TransferStatusType.Late]: 'red60',
    [TransferStatusType.Completed]: 'primaryColor',
    [AccountStatusType.Open]: 'blue40',
    [BalanceInventoryStatus.ReadyForRelease]: 'grey70',
    [BalanceInventoryStatus.Released]: 'green40',
}

export const receivingStatusTextColorMap: Record<ReceivingStatusExtended, StatusColorText> = {
    [ReceivingStatus.Received]: 'white',
    [ReceivingStatus.Rejected]: 'white',
    [ReceivingStatus.Arrived]: 'white',
    [ReceivingStatus.InTransit]: 'white',
    [ReceivingStatus.Booked]: 'grey90',
    [ReceivingStatus.Incomplete]: 'white',
    [ReceivingStatus.InProcess]: 'grey90',
    [ReceivingStatus.NotCalculated]: 'grey90',
    [ReceivingStatus.PendingIdentifier]: 'grey90',
    [ReceivingStatusExtension.Allocated]: 'white',
    [ReceivingStatusExtension.Remaining]: 'grey90',
    [TradeStatusNew.Closed]: 'white',
    [TradeStatusNew.Confirmed]: 'white',
    [TradeStatusNew.Draft]: 'grey90',
    [TradeStatusNew.Live]: 'grey90',
    [TradeInvoiceType.Invoice]: 'grey90',
    [TradeInvoiceType.Debit]: 'grey90',
    [TradeInvoiceType.Credit]: 'grey90',
    [TradeInvoiceType.Payment]: 'grey90',
    [TransferStatusType.Open]: 'grey90',
    [TransferStatusType.InProgress]: 'grey90',
    [TransferStatusType.Late]: 'white',
    [TransferStatusType.Completed]: 'white',
    [AccountStatusType.Open]: 'grey90',
    [BalanceInventoryStatus.Released]: 'grey90',
    [BalanceInventoryStatus.ReadyForRelease]: 'white',
}

// utils for release flow
export enum ReleaseFlowDesignation {
    Selected = 'SELECTED',
    Progress = 'PROGRESS',
    Remaining = 'REMAINING',
}

export const releaseFlowDesignationColorMap: Record<ReleaseFlowDesignation, StatusColorLightest> = {
    [ReleaseFlowDesignation.Selected]: 'green40',
    [ReleaseFlowDesignation.Progress]: 'blue40',
    [ReleaseFlowDesignation.Remaining]: 'grey40',
}

export function getReceivingStatusBackgroundColor(
    status: ReceivingStatusExtended | null,
): StatusColorLightest | StatusColorLight {
    return getBackgroundColorGeneric(receivingStatusBackgroundColorMap, status)
}

export function getReceivingStatusProgressBackgroundColor(
    status: ReceivingStatusExtended | null,
): StatusColorLightest | StatusColorLight {
    return getBackgroundColorGeneric(receivingStatusProgressBackgroundColorMap, status)
}

export function getReceivingStatusTextColor(
    status:
        | ReceivingStatus
        | TradeStatusNew
        | TradeInvoiceType
        | TransferStatus
        | BalanceInventoryStatus
        | ReceivingStatusExtension
        | AccountStatusType
        | null,
): StatusColorText {
    return getTextColorGeneric(receivingStatusTextColorMap, status)
}

export function getReleaseFlowDesignationColor(status: ReleaseFlowDesignation): StatusColorLightest | StatusColorLight {
    return getBackgroundColorGeneric(releaseFlowDesignationColorMap, status)
}
