import {useCallback, useEffect, useState} from 'react'

export const useCurrentElementIntersects = <T extends HTMLElement>(
    options: () => IntersectionObserverInit,
    active: boolean = true,
) => {
    const [intersects, setIntersects] = useState(false)
    const [el, setEl] = useState<HTMLElement>()
    useEffect(() => {
        if (!active || !el) {
            return () => {}
        }

        const intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                setIntersects(entry.isIntersecting)
            })
        }, options())
        intersectionObserver?.observe(el)
        return () => {
            intersectionObserver?.disconnect()
        }
    }, [options, active, el])

    const ref = useCallback(
        (el: T | null) => {
            if (!el || !active) {
                return
            }

            setEl(el)
        },
        [active],
    )

    return [ref, intersects] as const
}
