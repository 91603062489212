import MUICircularProgress, {CircularProgressProps as MUICircularProgressProps} from '@mui/material/CircularProgress'
import cn from 'classnames'

export type CircularProgressProps = MUICircularProgressProps

/**
 * Progress indicators commonly known as spinners, express an unspecified wait time or display the length of a process.
 */
export const CircularProgress = ({className, ...props}: CircularProgressProps) => (
    <MUICircularProgress className={cn('CircularProgress', className)} color="primary" {...props} />
)

export default CircularProgress
