import {Components, ComponentsOverrides, Theme, toggleButtonClasses} from '@mui/material'

// For the standard and primary toggleButtons
const disabledClasses = (theme: Theme) => ({
    '&.Mui-disabled, &.Mui-selected.Mui-disabled': {
        backgroundColor: theme.palette.grey20,
        color: theme.palette.text.secondary,
        border: 'none',
    },
    '&.Mui-selected.Mui-disabled': {
        backgroundColor: theme.palette.grey30,
    },
})

export const MuiToggleButton: Components<Theme>['MuiToggleButton'] = {
    defaultProps: {
        color: 'primary',
    },
    styleOverrides: {
        root: ({theme}) => ({
            padding: '5px 16px',
            textTransform: 'none',
            [`&.${toggleButtonClasses.secondary}:not(.${toggleButtonClasses.selected})`]: {
                color: theme.palette.text.light,
            },

            [`&.${toggleButtonClasses.secondary}.${toggleButtonClasses.disabled}:not(.${toggleButtonClasses.selected})`]:
                {
                    backgroundColor: theme.palette.primary[800],
                },

            '&.MuiToggleButton-sizeMedium': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
            },
            '&.MuiToggleButton-sizeLarge': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
            },
            '&.MuiToggleButton-sizeSmall': {
                fontSize: theme.typography.body2.fontSize,
                lineHeight: theme.typography.body2.lineHeight,
            },

            '&.MuiToggleButton-standard': {
                '&.Mui-selected': {
                    color: theme.palette.black,
                    backgroundColor: theme.palette.white,
                    borderColor: theme.palette.grey40,
                    zIndex: 1, // Solves a small glitch
                    boxShadow: theme.shadows[1],
                },
                '&:hover:not(.Mui-selected)': {
                    color: theme.palette.grey70,
                    backgroundColor: theme.palette.grey40,
                },
                ...disabledClasses(theme),
            },

            '&.MuiToggleButton-primary': {
                color: theme.palette.grey50,
                '&.Mui-selected': {
                    color: theme.palette.primary.contrastText,
                    backgroundColor: theme.palette.primary.main,
                    zIndex: 1, // Solves a small glitch
                    boxShadow: theme.shadows[1],
                },
                '&:hover:not(.Mui-selected)': {
                    color: theme.palette.grey70,
                    backgroundColor: theme.palette.grey40,
                },
                ...disabledClasses(theme),
            },

            '&.MuiToggleButton-secondary': {
                color: theme.palette.grey70,
                '&.Mui-selected': {
                    color: theme.palette.black,
                    backgroundColor: theme.palette.white,
                    zIndex: 1, // Solves a small glitch
                    border: `1px solid ${theme.palette.black}`,
                    boxShadow: theme.shadows[1],
                },
                '&.Mui-disabled, &.Mui-selected.Mui-disabled': {
                    color: theme.palette.grey60,
                    borderColor: theme.palette.grey60,
                    backgroundColor: theme.palette.grey70,
                },
                '&:not(.Mui-selected).Mui-disabled': {
                    border: 'none',
                },
                '&:hover:not(.Mui-selected)': {
                    color: theme.palette.grey50,
                },
            },
        }),
        sizeSmall: {
            padding: '3px 14px',
        },
        sizeLarge: {
            padding: '7px 18px',
        },
    } as ComponentsOverrides<Theme>['MuiToggleButton'],
}

export default MuiToggleButton
