import {ForwardedRef, forwardRef, ReactElement} from 'react'
import MUITypography, {TypographyProps as MUITypographyProps} from '@mui/material/Typography'
import cn from 'classnames'
import {includes, isNil} from 'lodash-es'

import * as colors from '@waybridge/wui/ThemeProvider/colors'

export type TypographyProps<T extends MUITypographyProps['variant'] = MUITypographyProps['variant']> = Omit<
    MUITypographyProps,
    'color' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'variant'
> & {
    /** The component that will be used to render the element */
    component?: 'span' | 'div' | 'h1' | 'h2' | 'p'
    color?: 'inherit' | colors.Color
    noWrap?: boolean
    /** Margin under the text */
    gutterBottom?: boolean
    align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
    variant?: T
    fontWeight?: T extends 'body2-monospace' | 'body1-monospace' ? 'bold' | 'regular' : 'medium' | 'regular'
}

const headerVariants = ['h3', 'h4', 'h5', 'h6']

function getExtraProps(variant?: MUITypographyProps['variant'], role?: React.AriaRole) {
    if (!isNil(role)) {
        return {role}
    }

    if (variant && includes(headerVariants, variant)) {
        return {
            role: 'heading',
            'aria-level': Number(variant.replace('h', '')),
        }
    }

    return {}
}

/**
 * Use typography to present your design and content as clearly and efficiently as possible.
 */
export const Typography = forwardRef(function Typography<T extends MUITypographyProps['variant'] = 'body1'>(
    {
        component,
        variant = 'body1',
        noWrap = false,
        gutterBottom = false,
        children,
        className,
        role,
        ...props
    }: TypographyProps<T>,
    ref: ForwardedRef<HTMLElement>,
) {
    const extraProps = getExtraProps(variant, role)

    return (
        <MUITypography
            className={cn('Typography', className)}
            component={component ? component : noWrap ? 'div' : 'span'}
            gutterBottom={gutterBottom}
            noWrap={noWrap}
            ref={ref}
            variant={variant}
            {...extraProps}
            {...props}>
            {children}
        </MUITypography>
    )
})

export default Typography as any as <T extends MUITypographyProps['variant']>(
    p: TypographyProps<T>,
    ref: ForwardedRef<HTMLElement>,
) => ReactElement
