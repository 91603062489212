import {createTheme, ThemeOptions} from '@mui/material'
import {ColorPartial} from '@mui/material/styles/createPalette'

import * as colors from '../colors'
import {DesignColors} from '../colors'
import MuiAlert from './components/MuiAlert'
import MuiAutocomplete from './components/MuiAutocomplete'
import MuiAvatar from './components/MuiAvatar'
import MuiAvatarGroup from './components/MuiAvatarGroup'
import MuiButton from './components/MuiButton'
import MuiCalendarOrClockPicker from './components/MuiCalendarOrClockPicker'
import MuiCalendarPicker from './components/MuiCalendarPicker'
import MuiCheckbox from './components/MuiCheckbox'
import MuiChip from './components/MuiChip'
import MuiDialog from './components/MuiDialog'
import MuiDialogActions from './components/MuiDialogActions'
import MuiDialogContent from './components/MuiDialogContent'
import MuiDialogTitle from './components/MuiDialogTitle'
import MuiDivider from './components/MuiDivider'
import MuiFilledInput from './components/MuiFilledInput'
import MuiFormHelperText from './components/MuiFormHelperText'
import MuiFormLabel from './components/MuiFormLabel'
import MuiGrid from './components/MuiGrid'
import MuiIconButton from './components/MuiIconButton'
import MuiInput from './components/MuiInput'
import MuiInputAdornment from './components/MuiInputAdornment'
import MuiInputBase from './components/MuiInputBase'
import MuiInputLabel from './components/MuiInputLabel'
import MuiLink from './components/MuiLink'
import MuiList from './components/MuiList'
import MuiListSubheader from './components/MuiListSubheader'
import MuiMenu from './components/MuiMenu'
import MuiMenuItem from './components/MuiMenuItem'
import MuiMonthPicker from './components/MuiMonthPicker'
import MuiOutlinedInput from './components/MuiOutlinedInput'
import MuiPickersDay from './components/MuiPickersDay'
import MuiRadio from './components/MuiRadio'
import MuiSelect from './components/MuiSelect'
import MuiSnackbar from './components/MuiSnackbar'
import MuiStepLabel from './components/MuiStepLabel'
import MuiSvgIcon from './components/MuiSvgIcon'
import MuiSwitch from './components/MuiSwitch'
import MuiTab from './components/MuiTab'
import MuiTableCell from './components/MuiTableCell'
import MuiTableRow from './components/MuiTableRow'
import MuiTabs from './components/MuiTabs'
import MuiTextField from './components/MuiTextField'
import MuiToggleButton from './components/MuiToggleButton'
import MuiToggleButtonGroup from './components/MuiToggleButtonGroup'
import MuiTooltip from './components/MuiTooltip'

// Any color aliases can be defined here
const colorTokens = {...colors, 'button-success': colors.green20}
// Define all our color token keys inside of theme.palette.tokens

declare module '@mui/material/styles/createPalette' {
    type DesignTokenMap<C extends Record<string, unknown>, T> = {[P in keyof C]: T}
    type OptionalDesignTokenMap<C extends Record<string, unknown>, T> = {[P in keyof C]?: T}

    /* eslint-disable @typescript-eslint/no-empty-interface */
    interface Palette extends DesignTokenMap<typeof colorTokens, string> {}
    interface PaletteColor extends ColorPartial {}
    /* eslint-enable @typescript-eslint/no-empty-interface */
}
const weights = {
    regular: 400,
    medium: 500,
    bold: 600,
}

export const iconColor = {
    conveyance: colors.blue60,
}

const text = {
    light: 'white',
    dark: 'black',
    primary: colors.black,
    secondary: colors.grey70,
    disabled: colors.grey60,
}

export const typography = {
    h3: {
        fontSize: '2rem',
        lineHeight: '2.625rem',
        fontWeight: weights.regular,
        fontFamily: 'Roboto',
    },
    h4: {
        fontSize: '1.5rem',
        lineHeight: '1.875rem',
        fontWeight: weights.regular,
        fontFamily: 'Roboto',
    },
    h5: {
        fontSize: '1.25rem',
        lineHeight: '1.625rem',
        fontWeight: weights.regular,
        fontFamily: 'Roboto',
    },
    h6: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: weights.regular,
        fontFamily: 'Roboto',
    },
    body1: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: weights.regular,
        '@media print': {
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        fontFamily: 'Roboto',
    },
    body2: {
        fontSize: '0.75rem',
        lineHeight: '1.125rem',
        fontWeight: weights.regular,
        '@media print': {
            fontSize: '1rem',
        },
        fontFamily: 'Roboto',
    },
    icons: {
        small: {
            fontSize: '1rem',
        },
        medium: {
            fontSize: '1.25rem',
        },
        large: {
            fontSize: '1.6875rem',
        },
        extraLarge: {
            fontSize: '2.5rem',
        },
        conveyance: {
            fontSize: '1.5rem',
        },
        conveyanceHighlighted: {
            fontSize: '1.5rem',
            color: iconColor.conveyance,
        },
    },
    caption: {
        fontSize: '0.625rem',
        lineHeight: '1rem',
        fontWeight: weights.regular,
        fontFamily: 'Roboto',
    },
    tooltipBody: {
        fontSize: '0.8125rem',
        color: text.light,
        lineHeight: '1.125rem',
        '@media print': {
            fontSize: '1rem',
        },
    },
    tooltipTitle: {
        fontSize: '0.8125rem',
        color: text.light,
        fontWeight: 'bold',
        lineHeight: '1rem',
    },
    number: {
        body1: {
            fontFamily: "'IBM Plex Mono', monospace",
            fontFeatureSettings: "'ss04'",
            fontSize: '0.875rem',
            lineHeight: '1.125rem',
        },
        body2: {
            fontFamily: "'IBM Plex Mono', monospace",
            fontFeatureSettings: "'ss04'",
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
        },
    },
}

const shadowColor = '#18274B14'
const shadowDetailColor = '#18274B1F'

export const brandGreen: ColorPartial = {
    100: '#CCE8EA',
    200: '#99D1D4',
    300: '#66BABF',
    400: '#33A3A9',
    500: '#008C94',
    600: '#037883',
    700: '#066573',
    800: '#0A5163',
}

const blueGrey: ColorPartial = {
    50: '#F0F4F8',
    100: '#D9E2EC',
    200: '#BCCCDC',
    300: '#9FB3C8',
    400: '#829AB1',
    500: '#627D98',
    600: '#486581',
    700: '#334E68',
    800: '#243B53',
    900: '#102A43',
}

const yellow: ColorPartial = {
    50: '#FFFAEB',
    100: '#FCEFC7',
    200: '#F8E3A3',
    300: '#F9DA8B',
    400: '#F7D070',
    500: '#E9B949',
    600: '#C99A2E',
    700: '#A27C1A',
    800: '#7C5E10',
    900: '#513C06',
}

// setting up these specific types to ensure that out status pills and components are consistent
export type StatusColorLightest = keyof Pick<
    DesignColors,
    | 'blue40'
    | 'blue60'
    | 'blue90'
    | 'green40'
    | 'red40'
    | 'green50'
    | 'primaryColor'
    | 'teal'
    | 'red60'
    | 'yellow40'
    | 'yellow60'
    | 'grey50'
    | 'grey70'
    | 'grey40'
>
export type StatusColorLight = keyof Pick<
    DesignColors,
    | 'blue40'
    | 'blue70'
    | 'blue90'
    | 'green40'
    | 'green90'
    | 'primaryColor'
    | 'teal'
    | 'red60'
    | 'yellow40'
    | 'yellow60'
    | 'grey40'
    | 'grey70'
>

export type StatusColorText = keyof Pick<DesignColors, 'grey90' | 'white'>

export const themeOptions: ThemeOptions = {
    palette: {
        ...colorTokens,
        secondary: {
            ...blueGrey,
            main: '#fff',
            dark: blueGrey[700],
            contrastText: blueGrey[900],
            light: blueGrey[300],
        },
        primary: {
            ...brandGreen,
            main: colors.primaryColor,
            dark: brandGreen[400],
            contrastText: '#fff',
            light: brandGreen[200],
        },
        error: {
            light: colors.red20,
            main: colors.red50,
            dark: colors.red80,
        },

        action: {
            hover: '#f0f4f8',
            disabled: colors.grey40,
            disabledBackground: colors.grey20,
        },

        info: {
            light: colors.blue20,

            main: '#008c95',
            dark: colors.blue80,
        },
        grey: {
            // 50: string;
            // 100: string;
            200: colors.grey20,
            300: colors.grey30,
            400: colors.grey40,
            500: colors.grey50,
            600: colors.grey60,
            700: colors.grey70,
            800: colors.grey80,
            900: colors.grey90,
        },

        warning: {
            light: colors.yellow20,
            main: colors.yellow50,
            dark: colors.yellow80,

            ...yellow,
        },
        gradient: {
            inventorySearch: 'radial-gradient(circle, #66BABF 6%, #008C94 52%, #0A5163 100%)',
        },
        text,
    },
    spacing: 8,

    typography: {
        fontFamily: '"Roboto", sans-serif',
        fontWeightRegular: weights.regular,
        fontWeightMedium: weights.medium,
        fontWeightBold: weights.bold,

        fontSize: 16,
        h3: typography.h3,
        h4: typography.h4,
        h5: typography.h5,
        h6: typography.h6,
        body1: typography.body1,
        body2: typography.body2,
        caption: typography.caption,
        // Custom variants
        'small-icons': typography.icons.small,
        'medium-icons': typography.icons.medium,
        'large-icons': typography.icons.large,
        'extra-large-icons': typography.icons.extraLarge,
        'conveyance-highlighted-icons': typography.icons.conveyanceHighlighted,
        'conveyance-icons': typography.icons.conveyance,

        'body1-monospace': typography.number.body1, // number S
        'body2-monospace': typography.number.body2, // number XS
        'tooltip-body': typography.tooltipBody,
        'tooltip-title': typography.tooltipTitle,
        // Unused variants
        h1: undefined,
        h2: undefined,
        subtitle1: undefined,
        subtitle2: undefined,
        overline: undefined,
        button: {
            ...typography.body1,
            textTransform: 'none',
        },
    },
    // We only support certain shadows at the moment
    /**
     * Usage for shadows:
     *  1. toggle, cards
     *  8. sticky nav
     *  9. sticky left column
     * 10. sticky right right
     * 11. n/a
     * 12. dropdowns, selects, autocompletes
     * 24. modals
     */

    shadows: [
        'none',
        `0px 4px 4px -2px ${shadowColor}, 0px 2px 4px -2px ${shadowDetailColor}`, // 1
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        `0px 8px 16px -6px ${shadowColor}, 0px 6px 8px -6px ${shadowDetailColor}`, // 8, regular or bottom shadow
        `8px 0px 16px -6px ${shadowColor}, 6px 0px 8px -6px ${shadowDetailColor}`, // 9, on-the-right version of 8
        `-8px 0px 16px -6px ${shadowColor}, -6px 0px 8px -6px ${shadowDetailColor}`, // 10, on-the-left version of 8
        `0px -8px 16px -6px ${shadowColor}, 0px -6px 8px -6px ${shadowDetailColor}`, // 11, top version of 8
        `0px 8px 20px -3px ${shadowColor}, 0px 6px 14px -3px ${shadowDetailColor}`, // 12
        'none',
        'none',
        'none',
        'none',
        'none',
        '0px -6px 8px -6px rgba(24, 39, 75, 0.12), 0px -8px 16px -6px rgba(24, 39, 75, 0.08);', // 18 - shadow on top
        'none',
        'none',
        'none',
        'none',
        'none',
        `0px 18px 88px -4px ${shadowColor}, 0px 8px 28px -6px ${shadowDetailColor}`, // 24
    ],

    border: {
        button: {
            radius: '4px',
        },
        toggleGroup: {
            radius: '18px',
        },
    },
    components: {
        MuiAvatar,
        MuiLink,
        MuiIconButton,
        MuiButton,
        MuiTableCell,
        MuiCalendarPicker,
        MuiCalendarOrClockPicker,
        MuiDialogTitle,
        MuiInputBase,
        MuiTableRow,
        MuiToggleButton,
        MuiToggleButtonGroup,
        MuiAlert,

        MuiAutocomplete,
        MuiAvatarGroup,
        MuiCheckbox,
        MuiChip,
        MuiDialog,
        MuiDialogActions,
        MuiDialogContent,
        MuiDivider,
        MuiFilledInput,
        MuiFormHelperText,
        MuiFormLabel,
        MuiGrid,
        MuiInput,
        MuiInputAdornment,
        MuiInputLabel,
        MuiSvgIcon,
        MuiList,
        MuiListSubheader,
        MuiMenu,
        MuiMenuItem,
        MuiMonthPicker,
        MuiOutlinedInput,
        MuiPickersDay,
        MuiRadio,
        MuiSelect,
        MuiSnackbar,
        MuiStepLabel,
        MuiSwitch,
        MuiTab,
        MuiTabs,
        MuiTextField,
        MuiTooltip,
    },
}

export const theme = createTheme(themeOptions)

export default theme
