import {forwardRef} from 'react'
import {styled} from '@mui/material'

import {StatusColorLight, StatusColorLightest, StatusColorText} from '@waybridge/wui/ThemeProvider/minehub-theme/theme'

const StatusPillInner = styled('div')(({theme}) => ({
    padding: theme.spacing(0.25, 1.25),
    borderRadius: '16px',
    background: theme.palette.grey50,
    ...theme.typography.caption,
    letterSpacing: '1.5px',
    fontWeight: '500',
    textAlign: 'center',
    textTransform: 'uppercase',
    display: 'inline-block',
}))
type PillItemStatusProps = {
    text: string
    colors: {
        text: StatusColorText
        background: StatusColorLightest | StatusColorLight
    }
}
export const PillItemStatus = forwardRef<HTMLDivElement, PillItemStatusProps>(({text, colors}, ref) => (
    <StatusPillInner ref={ref} sx={{bgcolor: colors.background, color: colors.text}}>
        {text}
    </StatusPillInner>
))

PillItemStatus.displayName = 'PillItemStatus'
