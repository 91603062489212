import {forwardRef} from 'react'
import MUITableRow, {TableRowProps as MUITableRowProps} from '@mui/material/TableRow'
import cn from 'classnames'

export type TableRowProps = MUITableRowProps

/**
 * TableRows ...
 */
export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(function TableRow({className, ...props}, ref) {
    return <MUITableRow className={cn('TableRow', className)} ref={ref} {...props} />
})

export default TableRow
