import MUIDialogTitle, {DialogTitleProps as MUIDialogTitleProps} from '@mui/material/DialogTitle'
import cn from 'classnames'

import {styled} from '@waybridge/wui'
import IconButton from '@waybridge/wui/IconButton'
import {CloseIcon} from '@waybridge/wui/Icons'

const Title = styled(MUIDialogTitle)(({theme}) => ({
    ...(theme.typography.h5 as {fontSize: string; lineHeight: string}),
    fontWeight: theme.typography.fontWeightMedium,
}))

const CloseButton = styled(IconButton)(({theme}) => ({
    position: 'absolute',
    top: `calc(${theme.spacing(3)} - 4px)`,
    right: `calc(${theme.spacing(3)} - 4px)`,
}))

export type DialogTitleProps = {
    // Function to close the dialog on click and will display a close icon.
    onClose?: () => void
} & MUIDialogTitleProps

/**
 * DialogTitle is used to display a title in a dialog inside of `Dialog`. Pass
 * `onClose` to show a close button icon.
 */
export const DialogTitle = ({className, children, onClose, ...props}: DialogTitleProps) => (
    <Title className={cn('DialogTitle', className)} {...props}>
        {children}
        {onClose ? (
            <CloseButton aria-label="Close" onClick={onClose} size="large" variant="white">
                <CloseIcon />
            </CloseButton>
        ) : null}
    </Title>
)

export default DialogTitle
