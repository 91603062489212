import {Components, Theme} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

export const MuiAlert: Components<Theme>['MuiAlert'] = {
    defaultProps: {
        elevation: 8,
        variant: 'filled',
    },
    styleOverrides: {
        root: ({theme}) => ({
            width: '100%',
            padding: theme.spacing(2),
            color: theme.palette.black,
            borderRadius: theme.border.button.radius,
            borderWidth: '1px',
            borderStyle: 'solid',
            ...theme.typography.body1,

            '.MuiAlert-action button': {
                transform: 'translateY(-50%)',
            },
        }),
        filledSuccess: ({theme}) => ({
            backgroundColor: theme.palette.green10,
            borderColor: theme.palette.primary.main,
            '.MuiAlert-icon svg': {
                color: theme.palette.primary.main,
            },
        }),
        filledError: ({theme}) => ({
            backgroundColor: theme.palette.red10,
            borderColor: theme.palette.red50,

            '.MuiAlert-icon svg': {
                color: theme.palette.red50,
            },
        }),
        filledWarning: ({theme}) => ({
            backgroundColor: theme.palette.yellow10,
            borderColor: theme.palette.yellow50,

            '.MuiAlert-icon svg': {
                color: theme.palette.yellow50,
            },
        }),
    } as ComponentsOverrides<Theme>['MuiAlert'],
}

export default MuiAlert
