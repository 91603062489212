import {ColumnConstraints, ColumnState} from '@waybridge/wui/CustomizeTableColumnsDialog/types'

import {TradeManagerV2$data} from '@/__generated__/TradeManagerV2.graphql'
import {TradeManagerV2SummaryQuery$data} from '@/__generated__/TradeManagerV2SummaryQuery.graphql'
import {TradeFulfillmentType, TradeType} from '@/__generated__/types'

export {OrderingDirection, TradeFulfillmentType, TradeStatus, TradeType} from '@/__generated__/types'

export interface Organization {
    id: number
    name: string
    photo: string
}

// filter types
type Summary = NonNullable<NonNullable<TradeManagerV2SummaryQuery$data['tradeManagerV2List']>['summary']>
export type Destination = NonNullable<Summary['destinations'][0]>
export type Counterparty = NonNullable<Summary['counterparties'][0]>
export type Material = NonNullable<Summary['materialSpecs'][0]>

export type Unit = 'mt' | 'lb'

// Hey! Psssst! Be very careful changing anything about this enum.
// These strings are persisted to localStorage and if you change them
// or remove fields or add fields... we need to successfully migrate
// any stored settings
export enum Field {
    settings = 'settings',
    expand = 'mrt-row-expand',
    tradePk = 'tradePk',
    tradeName = 'tradeName',
    counterparties = 'Counterparty',
    destinations = 'destinations',
    specification = 'material',
    material = 'newMaterial',
    tradeBuyType = 'tradeBuyType',
    tradeFulfillmentType = 'tradeFulfillmentType',
    regionName = 'regionName',
    buyerReferenceId = 'buyerReferenceId',
    sellerReferenceId = 'sellerReferenceId',
    schedule = 'schedule',
    orderNumber = 'orderNumber',
    orderSONumber = 'orderSONumber',
    quota = 'Quota',
    remaining = 'Remaining',
    booked = 'Booked',
    inTransit = 'InTransit',
    delivered = 'Delivered',
    allocated = 'Allocated',
    received = 'Received',
    progress = 'Progress',
}

export interface RowData {
    id: string
    materialsLength: number
    tradePk: QueryData['tradePk']
    materialName: string
    buyerPk: QueryData['buyerPk']
    declaredLocationErpIntegrationEnabled?: boolean | null
    buyerHasErp: boolean
    subquotaPk: number
    tradeName: QueryData['tradeName']
    tradePeriodPk: QueryData['pk']
    counterparties: {
        name: QueryData['buyerName'] | QueryData['sellerName'] | QueryData['shipperName']
        photo: QueryData['buyerPhoto'] | QueryData['sellerPhoto'] | QueryData['shipperPhoto']
        id: QueryData['buyerPk'] | QueryData['sellerPk'] | QueryData['shipperPk']
    }[]
    destinations?: QueryData['destinations']
    specification?: QueryData['commodityName']
    material?: QueryData['materialSpecs']
    tradeBuyType: QueryData['tradeType']
    tradeFulfillmentType: QueryData['tradeFulfillmentType']
    regionName: QueryData['regionName']
    buyerReferenceId: QueryData['buyerReferenceId']
    sellerReferenceId: QueryData['sellerReferenceId']
    schedule: {
        pk: QueryDataSubquotaInfo['schedulePk']
        status: QueryDataSubquotaInfo['scheduleStatus']
        updatedAt: QueryDataSubquotaInfo['scheduleUpdatedAt']
    }
    monthlyOrderNumbers: QueryDataSubquotaInfo['monthlyOrderNumbers']
    monthlyOrderNumbersEnhanced: QueryDataSubquotaInfo['monthlyOrderNumbersEnhanced']
    monthlyOrderSoNumbers: QueryDataSubquotaInfo['monthlyOrderSoNumbers']
    canAddPo: QueryData['canAddPo']
    commodityName: QueryData['commodityName']
    deliveryTerms: QueryData['deliveryTerms']
    locationsString?: string | null
    tradePeriod: QueryData['tradePeriod']
    allSubquotaMaterialSpecNames?: QueryData['allSubquotaMaterialSpecNames']
    allSubquotaLocationNames?: QueryData['allSubquotaLocationNames']
    pendingSubquotas: QueryData['pendingSubquotas']
    materialSpecs?: QueryData['materialSpecs']
    locationName: QueryDataSubquotaInfo['locationName']
    locationsLength: number
    po?: NonNullable<QueryDataSubquotaInfo['monthlyOrderNumbers']>[0]
    startDate: QueryData['startDate']
    bookedQuantityLb: QueryDataSubquotaInfo['bookedQuantityLb']
    receivedQuantityLb: QueryDataSubquotaInfo['receivedQuantityLb']
    subquotaDeclarationStatuses: Array<QueryDataSubquotaInfo['subquotaDeclarationStatus']>
    subquotas: QueryData['subquotaInfoEnhanced']
    declaredQuantityLb: QueryDataSubquotaInfo['declaredQuantityLb']
    deliveredQuantityLb: QueryDataSubquotaInfo['deliveredQuantityLb']
    inTransitQuantityLb: QueryDataSubquotaInfo['inTransitQuantityLb']
    remainingQuantityLb: QueryDataSubquotaInfo['remainingQuantityLb']
    allocatedQuantityLb: QueryDataSubquotaInfo['remainingQuantityLb']
    bookedQuantityMt: QueryDataSubquotaInfo['bookedQuantityMt']
    declaredQuantityMt: QueryDataSubquotaInfo['declaredQuantityMt']
    receivedQuantityMt: QueryDataSubquotaInfo['receivedQuantityMt']
    deliveredQuantityMt: QueryDataSubquotaInfo['deliveredQuantityMt']
    inTransitQuantityMt: QueryDataSubquotaInfo['inTransitQuantityMt']
    remainingQuantityMt: QueryDataSubquotaInfo['remainingQuantityMt']
    allocatedQuantityMt: QueryDataSubquotaInfo['remainingQuantityMt']
    consignmentAccountPk: QueryData['consignmentAccountPk']
    numSubrows: number
}

export interface Settings {
    // customized column order and visibility
    columns: ColumnState[]

    // from settings dialog
    unit: Unit

    // date to start querying from
    startDate?: string | null

    // top level search term
    search?: string[] | null

    // column level filters
    [Field.tradePk]?: string | null
    counterparties?: string | null
    [Field.destinations]?: string | null
    [Field.material]?: string | null
    [Field.specification]?: string | null
    [Field.tradeBuyType]?: string | null
    [Field.tradeFulfillmentType]?: string | null
    [Field.orderNumber]?: string | null
    [Field.regionName]?: string | null
    [Field.tradeName]?: string | null
    [Field.buyerReferenceId]?: string | null
    [Field.sellerReferenceId]?: string | null
}

export const tradeManagerDefaultColumnOrder: ColumnState[] = [
    {field: Field.expand, enabled: true},
    {field: Field.counterparties, enabled: true},
    {field: Field.tradePk, enabled: true},
    {field: Field.tradeName, enabled: false},
    {field: Field.tradeBuyType, enabled: true},
    {field: Field.tradeFulfillmentType, enabled: false},
    {field: Field.regionName, enabled: false},
    {field: Field.buyerReferenceId, enabled: false},
    {field: Field.sellerReferenceId, enabled: false},
    {field: Field.schedule, enabled: true},
    {field: Field.material, enabled: true},
    {field: Field.specification, enabled: true},
    {field: Field.destinations, enabled: true},
    {field: Field.orderNumber, enabled: true},
    {field: Field.orderSONumber, enabled: false},
    {field: Field.quota, enabled: true},
    {field: Field.remaining, enabled: true},
    {field: Field.booked, enabled: false},
    {field: Field.inTransit, enabled: true},
    {field: Field.delivered, enabled: false},
    {field: Field.allocated, enabled: false},
    {field: Field.received, enabled: false},
    {field: Field.progress, enabled: true},
]

export const columnContraints: Partial<Record<Field, ColumnConstraints>> = {
    [Field.counterparties]: {required: true},
    [Field.tradePk]: {required: true},
    [Field.quota]: {required: true},
    [Field.progress]: {required: true},
}

export enum GlobalFilter {
    DeclarationsDue = 'declarations',
    SchedulesDue = 'schedules',
    OpenOrders = 'orders',
    MissingPOs = 'pos',

    InTransit = 'transit',
    NotDeliveredReceived = 'not-delivered-received',
}

export type QueryData = NonNullable<TradeManagerV2$data['tradeManagerV2List']>['edges'][0]['node']
export type QueryDataSubquotaInfo = NonNullable<QueryData['subquotaInfoEnhanced'][0]>

export interface TableData {
    id: string
    counterparties: {
        name: QueryData['buyerName'] | QueryData['sellerName'] | QueryData['shipperName']
        photo: QueryData['buyerPhoto'] | QueryData['sellerPhoto'] | QueryData['shipperPhoto']
        id: QueryData['buyerPk'] | QueryData['sellerPk'] | QueryData['shipperPk']
    }[]
    tradePeriodPk: QueryData['pk']
    tradePk: QueryData['tradePk']
    tradeName: QueryData['tradeName']
    buyerReferenceId: QueryData['buyerReferenceId']
    sellerReferenceId: QueryData['sellerReferenceId']
    regionName: QueryData['regionName']
    tradeBuyType: QueryData['tradeType']
    buyerHasErp: QueryData['buyerHasErp']
    buyerPk: QueryData['buyerPk']
    buyerName: QueryData['buyerName']
    sellerPk: QueryData['sellerPk']
    sellerName: QueryData['sellerName']
    shipperPk: QueryData['shipperPk']
    shipperName: QueryData['shipperName']
    materialName: QueryDataSubquotaInfo['materialName']
    materialsLength: number
    subquotaPk: QueryDataSubquotaInfo['subquotaPk']
    declaredLocationErpIntegrationEnabled: QueryDataSubquotaInfo['declaredLocationErpIntegrationEnabled']
    monthlyOrderNumbers: QueryDataSubquotaInfo['monthlyOrderNumbers']
    monthlyOrderSoNumbers: QueryDataSubquotaInfo['monthlyOrderSoNumbers']
    monthlyOrderNumbersEnhanced: QueryDataSubquotaInfo['monthlyOrderNumbersEnhanced']
    canAddPo: QueryData['canAddPo']
    schedulePk: QueryDataSubquotaInfo['schedulePk']
    scheduleStatus: QueryDataSubquotaInfo['scheduleStatus']
    scheduleUpdatedAt: QueryDataSubquotaInfo['scheduleUpdatedAt']
    commodityName: QueryData['commodityName']
    deliveryTerms: QueryData['deliveryTerms']
    locationsString?: string | null
    allSubquotaMaterialSpecNames?: QueryData['allSubquotaMaterialSpecNames']
    allSubquotaLocationNames?: QueryData['allSubquotaLocationNames']
    pendingSubquotas: QueryData['pendingSubquotas']
    materialSpecs?: QueryData['materialSpecs']
    destinations?: QueryData['destinations']
    locationName: QueryDataSubquotaInfo['locationName']
    locationsLength: number
    po?: NonNullable<QueryDataSubquotaInfo['monthlyOrderNumbers']>[0]
    startDate: QueryData['startDate']
    bookedQuantityLb: QueryDataSubquotaInfo['bookedQuantityLb']
    receivedQuantityLb: QueryDataSubquotaInfo['receivedQuantityLb']
    subquotaDeclarationStatuses: Array<QueryDataSubquotaInfo['subquotaDeclarationStatus']>
    subquotas: QueryData['subquotaInfoEnhanced']
    tradePeriod: QueryData['tradePeriod']
    declaredQuantityLb: QueryDataSubquotaInfo['declaredQuantityLb']
    deliveredQuantityLb: QueryDataSubquotaInfo['deliveredQuantityLb']
    inTransitQuantityLb: QueryDataSubquotaInfo['inTransitQuantityLb']
    remainingQuantityLb: QueryDataSubquotaInfo['remainingQuantityLb']
    allocatedQuantityLb: QueryDataSubquotaInfo['remainingQuantityLb']
    bookedQuantityMt: QueryDataSubquotaInfo['bookedQuantityMt']
    declaredQuantityMt: QueryDataSubquotaInfo['declaredQuantityMt']
    receivedQuantityMt: QueryDataSubquotaInfo['receivedQuantityMt']
    deliveredQuantityMt: QueryDataSubquotaInfo['deliveredQuantityMt']
    inTransitQuantityMt: QueryDataSubquotaInfo['inTransitQuantityMt']
    remainingQuantityMt: QueryDataSubquotaInfo['remainingQuantityMt']
    allocatedQuantityMt: QueryDataSubquotaInfo['remainingQuantityMt']
    tradeFulfillmentType: QueryData['tradeFulfillmentType']
    consignmentAccountPk: QueryData['consignmentAccountPk']
    numSubrows: number
}

export type TableDataObject = TableData & {subRows?: Partial<TableData>[]}

// Define some types for single and multi value column filter
// since the MRT library defines ColumnFilter.value as unknown
// and leaving it unknown causes type errors.
export interface ColumnFilter {
    id: string
    value: {
        tradePk?: string
        tradeBuyType?: TradeType
        tradeFulfillmentType?: TradeFulfillmentType
        orderNumber?: string
        materialName?: string
        commodityName?: string
        regionName?: string
        tradeName?: string
        buyerReferenceId?: string
        sellerReferenceId?: string
    }
}

export interface ColumnMultiFilter {
    id: string
    value: Record<string, boolean>
}
