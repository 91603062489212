import {useRef, useState} from 'react'
import cn from 'classnames'
import {isNil, omit} from 'lodash-es'
import {FormattedMessage, useIntl} from 'react-intl'

import {styled} from '@waybridge/wui'
import {IconButton} from '@waybridge/wui/IconButton'
import {FilterIcon, FilterListIcon} from '@waybridge/wui/Icons'
import Popover from '@waybridge/wui/Popover'
import Stack from '@waybridge/wui/Stack'
import MuiTableCell, {TableCellProps as MuiTableCellProps} from '@waybridge/wui/TableCell'
import theme from '@waybridge/wui/ThemeProvider/minehub-theme/theme'
import Typography from '@waybridge/wui/Typography'

import Filter from './Filter'

export const classes = {
    root: 'WuiTableHeader-root',
    isFilterable: 'WuiTableHeader-isFilterable',
    isFiltered: 'WuiTableHeader-isFiltered',
    isSortable: 'WuiTableHeader-isSortable',
    isSorted: 'WuiTableHeader-isSorted',
    sortAsc: 'WuiTableHeader-sortAsc',
    sortDesc: 'WuiTableHeader-sortDesc',

    Stack: 'WuiTableHeader-Stack',
    Title: 'WuiTableHeader-Title',
    SortButton: 'WuiTableHeader-SortButton',
    SortIcon: 'WuiTableHeader-SortIcon',
    FilterButton: 'WuiTableHeader-FilterButton',
    FilterIcon: 'WuiTableHeader-FilterIcon',
}

const TinyIconButton = styled(IconButton)({
    '& .MuiSvgIcon-root': {
        fontSize: theme.typography['medium-icons'].fontSize,
    },
})

const BaseTableHeader = styled(MuiTableCell, {
    name: 'WuiTableHeader',
    slot: 'Root',
    shouldForwardProp: (prop) => prop !== 'searchParam',
})(({theme}) => ({
    borderBottom: 'none',
    padding: '12px',
    background: 'white',
    // we might want to a prop to determine whether to use a border or not
    borderRight: `1px solid ${theme.palette.grey30}`,

    '&:last-of-type': {
        paddingRight: '28px',
    },

    [`& .${classes.Title}`]: {
        color: 'black',
        marginRight: '8px',
    },

    [`& .${classes.SortIcon}`]: {
        color: theme.palette.grey50,
    },

    [`& .${classes.FilterIcon}`]: {
        color: theme.palette.grey50,
    },

    // the buttons are hidden by default and are revealed either by
    // hovering over the top component or when the are active
    [`& .${classes.SortButton}, & .${classes.FilterButton}`]: {
        visibility: 'hidden',
    },
    [`&:hover .${classes.SortButton}, &:hover .${classes.FilterButton}`]: {
        visibility: 'visible',
    },

    // overrides for states //

    [`&.${classes.isFilterable}`]: {},

    [`&.${classes.isFiltered}`]: {
        [`& .${classes.Title}`]: {
            color: theme.palette.primary.main,
        },
        [`& .${classes.FilterButton}`]: {
            visibility: 'visible',
        },
        [`& .${classes.FilterIcon}`]: {
            color: theme.palette.primary.main,
        },
    },

    [`&.${classes.isSorted}`]: {
        [`& .${classes.Title}`]: {
            color: theme.palette.primary.main,
        },
        [`& .${classes.SortButton}`]: {
            visibility: 'visible',
        },
        [`& .${classes.SortIcon}`]: {
            color: theme.palette.primary.main,
        },
    },

    [`&.${classes.sortAsc}`]: {
        [`& .${classes.SortIcon}`]: {
            transform: 'scaleY(-1)',
        },
    },

    [`&.${classes.sortDesc}`]: {},
}))

export type SortDirection = 'asc' | 'desc' | undefined

export type TableCellProps = Omit<MuiTableCellProps, 'sortDirection'> & {
    isFilterable?: boolean
    isFiltered?: boolean
    filterValue?: Record<string, any>
    onFilter?: (data: Record<string, any>) => void
    onClearFilter?: () => void

    isSortable?: boolean
    isSorted?: boolean
    onSort?: () => void

    sortDirection?: SortDirection

    FilterContent?: React.ElementType
}

const TableHeader = ({
    children,
    isFilterable = false,
    isFiltered = false,
    onFilter,
    filterValue,
    isSortable = false,
    isSorted = false,
    sortDirection,
    onSort,
    FilterContent,
    ...otherProps
}: TableCellProps) => {
    const intl = useIntl()
    const props = omit(otherProps, 'filterReducer', 'filterValue', 'resetFilterValue')
    const {size} = props
    const anchorEl = useRef<HTMLTableCellElement | null>(null)
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    const handleFilterClick = () => {
        setIsFilterOpen(true)
    }

    const handleFilterClose = () => {
        setIsFilterOpen(false)
    }

    const justifyContent = props.align && props.align === 'right' ? 'flex-end' : 'flex-start'

    const handleSort = () => {
        onSort && onSort()
    }

    const handleFilter = (data: Record<string, any>) => {
        handleFilterClose()
        onFilter && onFilter(data)
    }

    return (
        <BaseTableHeader
            {...props}
            className={cn(classes.root, props.className, {
                [classes.isFilterable]: isFilterable,
                [classes.isFiltered]: isFiltered,
                [classes.isSortable]: isSortable,
                [classes.isSorted]: isSorted,
                [classes.sortAsc]: sortDirection === 'asc',
                [classes.sortDesc]: sortDirection === 'desc',
            })}
            ref={anchorEl}
            sortDirection={sortDirection}>
            <Stack
                alignItems="center"
                className={classes.Stack}
                direction="row"
                justifyContent={justifyContent}
                {...(size === 'small' && {height: '14px'})}>
                <Typography className={classes.Title} fontWeight="medium" noWrap variant="body1">
                    {children}
                    {isSorted && sortDirection === 'asc' ? (
                        <span style={{display: 'none'}}>
                            &nbsp;
                            <FormattedMessage defaultMessage="sorted ascending" />
                        </span>
                    ) : null}
                    {isSorted && sortDirection === 'desc' ? (
                        <span style={{display: 'none'}}>
                            &nbsp;
                            <FormattedMessage defaultMessage="sorted descending" />
                        </span>
                    ) : null}
                </Typography>
                {isSortable ? (
                    <TinyIconButton
                        aria-label={
                            sortDirection === 'desc'
                                ? intl.formatMessage({defaultMessage: 'Sort descending'})
                                : intl.formatMessage({defaultMessage: 'Sort ascending'})
                        }
                        aria-pressed={isSorted}
                        className={classes.SortButton}
                        onClick={handleSort}
                        size="small">
                        <FilterListIcon className={classes.SortIcon} fontSize="small" />
                    </TinyIconButton>
                ) : null}
                {isFilterable ? (
                    <TinyIconButton
                        aria-label={intl.formatMessage({defaultMessage: 'Filter table'})}
                        aria-pressed={isFiltered}
                        className={classes.FilterButton}
                        onClick={handleFilterClick}
                        size="small">
                        <FilterIcon className={classes.FilterIcon} fontSize="inherit" />
                    </TinyIconButton>
                ) : null}
            </Stack>
            {isFilterable && !isNil(FilterContent) ? (
                <Popover
                    anchorEl={anchorEl.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={handleFilterClose}
                    open={isFilterOpen}>
                    <Filter onFilter={handleFilter} values={filterValue}>
                        <FilterContent />
                    </Filter>
                </Popover>
            ) : null}
        </BaseTableHeader>
    )
}

export default TableHeader
