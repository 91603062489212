import {Components, Theme} from '@mui/material'
import {ComponentsOverrides} from '@mui/material/styles'

export const MuiButton: Components<Theme>['MuiButton'] = {
    defaultProps: {
        disableElevation: true,
        variant: 'outlined',
        color: 'primary',
    },
    styleOverrides: {
        root: ({theme}) => ({
            borderRadius: theme.border.button.radius,
            padding: theme.spacing(0.75, 2),
            '&.MuiButton-sizeMedium': {
                '& .MuiButton-startIcon, & .MuiButton-endIcon': {
                    '& > *:nth-of-type(1)': {
                        fontSize: theme.typography['small-icons'].fontSize,
                    },
                },
            },
            '&.MuiButton-sizeSmall': {
                '& .MuiButton-startIcon, & .MuiButton-endIcon': {
                    marginRight: theme.spacing(0.75),
                    '& > *:nth-of-type(1)': {
                        fontSize: theme.typography['small-icons'].fontSize,
                    },
                },
            },
            '&.MuiButton-outlined': {
                color: theme.palette.grey80,
                borderColor: theme.palette.grey70,
                '&:not(.Mui-disabled):hover': {
                    backgroundColor: theme.palette.action.hover,
                    borderColor: theme.palette.common.black,
                    color: theme.palette.common.black,
                },
                '&.Mui-disabled': {
                    color: theme.palette.grey40,
                    borderColor: theme.palette.grey40,
                    backgroundColor: theme.palette.white,
                    pointerEvents: 'unset',
                    cursor: 'not-allowed',
                },
            },
            '&.MuiButton-contained': {
                padding: `calc(${theme.spacing(0.75)} + 1px) calc(${theme.spacing(2)} + 1px)`,
                '&.Mui-disabled': {
                    borderWidth: 0,
                    backgroundColor: theme.palette.grey40,
                    color: theme.palette.grey70,
                    pointerEvents: 'unset',
                    cursor: 'not-allowed',
                },
                '&.MuiButton-sizeSmall': {
                    padding: `calc(${theme.spacing(0.375)} + 1px) calc(${theme.spacing(1.25)} + 1px)`,
                },
            },
            '&.MuiButton-textPrimary': {
                '.Button-textContent': {
                    borderBottom: 'none',
                },
                '&.Mui-disabled': {
                    backgroundColor: 'inherit',
                    pointerEvents: 'unset',
                    cursor: 'not-allowed',
                },
            },
            '&.MuiButton-textSecondary': {
                color: theme.palette.primary.main,
                '.Button-textContent': {
                    borderBottom: 'none',
                },
                '&.Mui-disabled': {
                    backgroundColor: 'inherit',
                    color: theme.palette.grey40,
                    pointerEvents: 'unset',
                    cursor: 'not-allowed',
                },
            },
        }),
        text: ({theme}) => ({
            color: theme.palette.grey80,
        }),
        sizeMedium: {
            lineHeight: '18px',
        },
        sizeSmall: ({theme}) => ({
            fontSize: '0.75rem',
            lineHeight: '16px',
            padding: theme.spacing(0.375, 1.25),
        }),
    } as ComponentsOverrides<Theme>['MuiButton'],
}

export default MuiButton
