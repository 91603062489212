import {styled} from '@waybridge/wui'

const CompanyName = styled('h2')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    marginBlock: '12px',
    paddingInline: '20px',
    marginLeft: '26px',
    color: theme.palette.secondary.main,
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
}))

export default CompanyName
